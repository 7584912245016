import { useContext } from 'react'
import UserContext from '../../contexts/UserContext'
import { oc } from 'ts-optchain'

const useAccessRights = (allowedRoles: string[]) => {
  const { user, loading } = useContext(UserContext)
  const roles = oc(user)
    .roles([])
    .map(role => role.name)
  const roleNames = oc(user).rolesName([])
  let isAllowed: boolean = allowedRoles.length ? false : true

  for (const role of allowedRoles) {
    if (role && (roles.includes(role) || roleNames.includes(role))) {
      isAllowed = true
      break
    }
  }

  return {
    roleNames,
    isAllowed,
    loading,
  }
}

export const useIsMember = () => {
  const { isAllowed } = useAccessRights(['member'])
  return isAllowed
}

export default useAccessRights

import React from 'react'
import { Container, Card, CardBody } from 'reactstrap'

import Navbar from '../../shared/components/Navbar'
import Footer from '../../shared/components/Footer'
import Sidebar from './components/Sidebar'
import { RouteComponentProps } from 'react-router'
import BgDashboard from '../../assets/party-bg.png'
import BgReward from '../../assets/medal-bg.png'
import BgRoyalty from '../../assets/money-bg.png'
import useAccessRights from '../../shared/hooks/useAccessRights'
import { NavbarFixed } from '../../shared/components/NavbarFixed'

interface IApplicationLayout {
  children?: React.ReactNode
  match: RouteComponentProps['match']
}

const layout: 'default' | 'fixed-sidebar' = 'fixed-sidebar'

const ApplicationLayout = ({ children, match }: IApplicationLayout) => {
  const access = useAccessRights(['member'])
  let bgImage = undefined
  if (['/dashboard', '/team'].includes(match.path)) {
    if (access.isAllowed) {
      bgImage = BgDashboard
    }
  } else if (match.path === '/reward') {
    bgImage = BgReward
  } else if (['/royalty', '/invoice', '/withdrawal'].includes(match.path)) {
    bgImage = BgRoyalty
  }

  if (layout === 'fixed-sidebar') {
    return (
      <div className="applayout fixed-sidebar">
        <div className="sidebar">
          <Sidebar className="d-none d-md-inline-block" />
        </div>
        <div className="main-content">
          <NavbarFixed />
          <Card
            style={{
              // background: bgImage ? `url(${bgImage}) no-repeat` : undefined,
              backgroundSize: 'cover',
            }}
            className="shadow"
          >
            <CardBody>{children}</CardBody>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <div className="applayout">
      {/* <Navbar /> */}

      <Container className="app-container sidebar-fixed">
        <div className="d-flex">
          <Sidebar className="d-none d-md-inline-block" />

          <Card
            className="applayout__content"
            style={{
              // background: bgImage ? `url(${bgImage}) no-repeat` : undefined,
              backgroundSize: 'cover',
            }}
          >
            <CardBody>{children}</CardBody>
          </Card>
        </div>
      </Container>

      <Footer />
    </div>
  )
}

export default ApplicationLayout

import React, { Fragment, useContext } from 'react'
import classnames from 'classnames'
import {
  FaMoneyBill,
  FaCertificate,
  FaStar,
  FaTrophy,
  FaImages,
  FaNewspaper,
  FaFile,
  FaQuestion,
  FaArchive,
  FaUser,
  FaHome,
  FaCrown,
  FaGifts,
  FaFileInvoiceDollar,
  FaHistory,
  FaCoins,
  FaCartArrowDown,
} from 'react-icons/fa'
import { GiFamilyTree, GiGooeyEyedSun } from 'react-icons/gi'
import UserProfile from './UserProfile'
import MenuHeader from './MenuHeader'
import MenuItem from './MenuItem'
import OthersSidebar from './OthersSidebar'
import { useMemberSidebarNotificationQuery } from '../../../generated/graphql'
import { oc } from 'ts-optchain'
import { Badge } from 'reactstrap'
import useAccessRights from '../../../shared/hooks/useAccessRights'
import UserContext from '../../../contexts/UserContext'
import { useTranslation } from 'react-i18next'
import AppLogo from '../../../assets/app_logo.png'
import { useCounterNotification } from '../../../shared/hooks/useCounterNotification'

const Sidebar = ({ className, ...props }: SidebarProps) => {
  const { t } = useTranslation()
  return (
    <aside className={classnames('sidebar-wrapper', className)}>
      <section className="sidebar">
        <img src={AppLogo} alt="" width="200" />
        {props.mode === 'default' && <UserProfile />}
        <ul className="sidebar__list-menu">
          {/* <MenuHeader>{t('main_navigation')} </MenuHeader> */}
          <MenuList />
        </ul>
      </section>
    </aside>
  )
}

const MenuList = ({ className, ...props }: SidebarProps) => {
  const admins = useAccessRights(['superadmin', 'admin'])
  const memberOnly = useAccessRights(['member'])
  const { isSmall, isExpired, unPaid } = useContext(UserContext)
  if (admins.isAllowed) {
    return <AdminMenu />
  } else if (memberOnly.isAllowed) {
    if (isSmall || isExpired || unPaid) {
      return <SmallMenuList />
    } else {
      return <MemberMenu />
    }
  } else {
    return null
  }
}

const AdminMenu = () => {
  const { t } = useTranslation()
  const superadmin = useAccessRights(['superadmin'])
  const {
    memberNotification,
    pendingEpoints,
    pendingWithdrawals,
    rewardsNotif,
    orderAndAdminClaims,
  } = useCounterNotification()

  return (
    <Fragment>
      <MenuItem
        to="/dashboard"
        // icon={require('../../../assets/icons/ic_dashboard.svg')}
        icon={FaHome}
      >
        {t('dashboard')}
      </MenuItem>
      <MenuItem
        to="/member"
        // icon={require('../../../assets/icons/ic_myteam.svg')}
        icon={FaUser}
      >
        {t('member')}
        {memberNotification ? (
          <Badge color="danger" className="ml-2">
            {memberNotification}
          </Badge>
        ) : null}
      </MenuItem>
      <MenuItem to="/order" icon={FaCartArrowDown}>
        {t('order')}
        {orderAndAdminClaims > 0 ? (
          <Badge color="danger" className="ml-2">
            {orderAndAdminClaims}
          </Badge>
        ) : null}
      </MenuItem>
      {/* <MenuItem to="/star" icon={FaStar}>
        {t('star')}
        {pendingEpoints > 0 ? (
          <Badge color="danger" className="ml-2">
            {pendingEpoints}
          </Badge>
        ) : null}
      </MenuItem> */}
      <MenuItem to="/withdrawal" icon={FaMoneyBill}>
        {t('withdrawal')}
        {pendingWithdrawals > 0 ? (
          <Badge color="danger" className="ml-2">
            {pendingWithdrawals}
          </Badge>
        ) : null}
      </MenuItem>
      <MenuItem to="/transaction" icon={FaCertificate}>
        {t('administration_fee')}
      </MenuItem>
      <MenuItem to="/rewards-history" icon={FaHistory}>
        {t('rewards')}
        {rewardsNotif > 0 ? (
          <Badge color="danger" className="ml-2">
            {rewardsNotif}
          </Badge>
        ) : null}
      </MenuItem>
      {superadmin.isAllowed && (
        <MenuItem to="/manage-rewards" icon={FaGifts}>
          {t('manage_rewards')}
        </MenuItem>
      )}
      <MenuItem
        to="/network-tree"
        icon={<GiFamilyTree className="sidebar__menu-icon flip-vertical" />}
      >
        {t('network_tree')}
      </MenuItem>
      <MenuItem to="/top-earner" icon={FaTrophy}>
        {t('top_earner')}
      </MenuItem>
      <MenuItem to="/landing-settings" icon={FaImages}>
        {t('landing_page_settings')}
      </MenuItem>
      <MenuItem to="/news" icon={FaNewspaper}>
        {t('update_news')}
      </MenuItem>
      <MenuItem to="/others" icon={FaQuestion}>
        {t('others')}
      </MenuItem>
      {superadmin.isAllowed && (
        <MenuItem to="/sales-ranks" icon={FaArchive}>
          {t('sales_rank')}
        </MenuItem>
      )}
      <MenuItem to="/files" icon={FaFile}>
        {t('download_file')}
      </MenuItem>
      <MenuHeader>{t('others')}</MenuHeader>
      <OthersSidebar />
    </Fragment>
  )
}

const MemberMenu = () => {
  const { orderAndClaims, memberRewards } = useCounterNotification([
    'orders',
    'claims',
    'memberRewards',
  ])

  const { t } = useTranslation()
  return (
    <Fragment>
      <MenuItem
        to="/dashboard"
        // icon={require('../../../assets/icons/ic_dashboard.svg')}
        icon={FaHome}
      >
        {t('dashboard')}
      </MenuItem>
      <MenuItem to="/order" icon={FaCartArrowDown}>
        {t('order')}
        {orderAndClaims > 0 && (
          <Badge color="danger" className="ml-2">
            {orderAndClaims}
          </Badge>
        )}
      </MenuItem>
      <MenuItem to="/team" icon={GiGooeyEyedSun}>
        {t('my_referral')}
      </MenuItem>
      <MenuItem
        to="/network-tree"
        icon={<GiFamilyTree className="sidebar__menu-icon flip-vertical" />}
      >
        {t('network_tree')}
      </MenuItem>
      <MenuItem to="/royalty" icon={FaCoins}>
        {t('royalty')}
      </MenuItem>
      <MenuItem to="/reward" icon={FaCrown}>
        {t('rewards')}
        {memberRewards > 0 && (
          <Badge color="danger" className="ml-2">
            {memberRewards}
          </Badge>
        )}
      </MenuItem>
      <MenuItem to="/invoice" icon={FaFileInvoiceDollar}>
        {t('invoice')}
      </MenuItem>
      <MenuItem to="/withdrawal" icon={FaMoneyBill}>
        {t('withdrawal')}
      </MenuItem>
      <MenuItem to="/files" icon={FaFile}>
        {t('download_file')}
      </MenuItem>
      <MenuHeader>{t('others')}</MenuHeader>
      <OthersSidebar />
    </Fragment>
  )
}

const SmallMenuList = () => {
  const { t } = useTranslation()
  const { orderAndClaims } = useCounterNotification(['orders', 'claims'])

  return (
    <Fragment>
      <MenuItem to="/order" icon={FaCartArrowDown}>
        {t('order')}
        {orderAndClaims > 0 ? (
          <Badge color="danger" className="ml-2">
            {orderAndClaims}
          </Badge>
        ) : null}
      </MenuItem>
      <MenuItem to="/invoice" icon={FaFileInvoiceDollar}>
        {t('invoice')}
      </MenuItem>
      <MenuHeader>{t('others')}</MenuHeader>
      <OthersSidebar />
    </Fragment>
  )
}

export default Sidebar

// =============================================================================
// typedef
// =============================================================================

interface SidebarProps {
  className?: string
  mode: 'default' | 'fixed'
}

import React, { Fragment, useState, useContext } from 'react'
import { Button } from 'reactstrap'

import { useOthersQuery, Other } from '../../../generated/graphql'
import Modal from '../../../shared/components/Modal'
import { oc } from 'ts-optchain'
import { FaQuestionCircle } from 'react-icons/fa'
import TextEditor from '../../../shared/components/TextEditor'
import UserContext from '../../../contexts/UserContext'
import { useTranslation } from 'react-i18next'

const OthersSidebar = () => {
  const { t } = useTranslation()
  const { isSmall } = useContext(UserContext)
  const { data } = useOthersQuery({
    variables: {
      filter: {
        title_not_i_cont_all: isSmall
          ? ['kebijakan penggunaan', 'panduan member']
          : undefined,
      },
    },
  })
  const [selected, setSelected] = useState<Other | null>(null)

  function closeModal() {
    setSelected(null)
  }

  return (
    <Fragment>
      {oc(data)
        .others([])
        .map((other) => {
          return (
            <div key={other.id} className="other-item">
              <Button
                color="link"
                onClick={() => setSelected(other)}
                className="d-flex pl-0 align-items-center text-decoration-none"
              >
                <FaQuestionCircle className="other-item__icon" />
                <span className="other-item__title ml-2 text-nowrap">
                  {other.title}
                </span>
              </Button>
            </div>
          )
        })}

      <Modal
        isOpen={selected ? true : false}
        title={String(selected && selected.title)}
        toggle={closeModal}
        size="lg"
      >
        {selected && (
          <TextEditor readOnly data={String(selected.description)} />
        )}

        <div className="text-center mt-3">
          <Button outline color="primary" onClick={closeModal}>
            {t('close')}
          </Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default OthersSidebar

import { ADMIN_FEE, BANK_ACCOUNT, BONUS_EVENT, CYCLE_COMMISSIONS, CYCLE_LABEL, EPOINT_LABEL, PERIOD_LABEL, POINT_LABEL, REFERRAL_COMMISION_LABEL, SALES_RANK } from '../../shared/constants'

const id = {
  //common
  close: 'Tutup',
  back: 'Kembali',
  cancel: 'Batal',
  yes: 'Ya',
  no: 'Tidak',
  search: 'Cari',
  search_: 'Cari...',
  date: 'Tanggal',
  save: 'Simpan',
  change: 'Ubah',
  edit: 'Edit',
  delete: 'Hapus',
  agree: 'Setuju',
  //dashboard
  total_earning_member: 'Total Pendapatan Anggota',
  total_member: 'Total Anggota',
  total_star: `Total ${EPOINT_LABEL}`,
  total_withdraw: 'Total Penarikan',
  total_member_small: 'Total Anggota Small',
  used_star: `${EPOINT_LABEL} Terpakai`,
  total_saldo_member: 'Total Saldo Anggota',
  total_member_Medium: 'Total Anggota Medium',
  unused_star: `${EPOINT_LABEL} Belum Terpakai`,
  total_member_Large: 'Total Anggota Large',
  total_member_Extra_Large: 'Total Anggota Extra Large',
  current_status: 'Status Terkini',
  saldo_income: 'Saldo Pemasukan',
  total_income: 'Total Pemasukan',
  performa: 'Performa',
  cycle_achieved: `${CYCLE_LABEL} yang Tercapai`,
  this_period: `${PERIOD_LABEL.id} ini`,
  point: `${POINT_LABEL}`,
  left: 'Kiri',
  right: 'Kanan',
  active_member: 'Anggota Aktif',
  commission: 'Komisi',
  qualified: 'Memenuhi Syarat',
  this_year: 'Tahun Ini',
  referral_commision: REFERRAL_COMMISION_LABEL,
  cycle_commision: `Komisi ${CYCLE_LABEL}`,
  generation_residual_income: 'Penghasilan Residual Generasi',
  bonus_event: BONUS_EVENT,
  total_commision: 'Total Komisi',
  logout: 'Keluar',
  star_usage: `Penggunaan ${EPOINT_LABEL}`,

  //sidebar
  order: 'Order',
  account: 'Akun',
  main_navigation: 'Navigasi Utama',
  dashboard: 'Dasbor',
  member: 'Anggota',
  star: `${EPOINT_LABEL}`,
  withdrawal: 'Withdrawal',
  administration_fee: 'Administration Fee',
  rewards: 'Rewards',
  manage_rewards: 'Manage Rewards',
  network_tree: 'Network Tree',
  top_earner: 'Pendapatan Teratas',
  landing_page_settings: 'Pengaturan Halaman Depan',
  update_news: 'Pembaruan Berita',
  others: 'Lain-lain',
  sales_rank: `${SALES_RANK}`,
  download_file: 'Unduh File',
  my_referral: 'Rujukan Saya',
  royalty: 'Royalti',
  invoice: 'Tagihan',

  //member
  stars: `${EPOINT_LABEL}`,
  invoices: 'Faktur',
  list_referral: 'Daftar Referal',
  cycle_history: `Riwayat ${CYCLE_LABEL}`,
  point_history: `Riwayat ${POINT_LABEL}`,
  transaction_history: 'Riwayat Transaksi',
  details_user: 'Detail Pengguna',
  list_member: 'Daftar Anggota',
  new_member: 'Anggota Baru',
  upgraded_member: 'Anggota Upgrade',
  membership: 'Keanggotaan',
  join_date: 'Tanggal Bergabung',
  name: 'Nama',
  user_id: 'ID Pengguna',
  phone: 'Nomor Telepon',
  email: 'Email',
  status: 'Status',
  order_status: 'Order Status',
  payment_status: 'Payment Status',
  transaction_id: 'ID Transaksi',
  note: 'Keterangan',
  total_transfers: 'Total Transfer',
  payment_details: 'Detail Pembayaran',
  accept: 'Terima',
  reject: 'Tolak',
  new_member_registration_sales_ranking: `Member Baru`,
  amount_of_money_to_be_paid: 'Jumlah uang yang harus dibayar',
  amount_of_money_sent: 'Jumlah uang yang dikirim',
  transfer_to: 'Tujuan Transfer',
  company_transfer_account: BANK_ACCOUNT,
  name_of_the_sender: 'Nama Pengirim',
  sending_via: 'Pengiriman Via',
  accept_member: 'Terima Anggota',
  are_you_sure_you_want_to_accept_this_person_as_a_member:
    'Apakah Anda yakin ingin menerima orang ini sebagai member?',
  reject_member: 'Tolak Anggota',
  are_you_sure_you_want_to_reject_this_person_as_a_member:
    'Apakah Anda yakin ingin menolak orang ini sebagai member?',
  upgrade_from: 'Upgrade dari',
  confirm_upgrade_payment: 'Konfirmasi Pembayaran Upgrade',
  confirm_new_member_payment: 'Konfirmasi Pembayaran Member Baru',
  show_entries: 'Tampilkan Entri',
  accepted: 'Diterima',
  membership_payments: 'Pembayaran Membership',
  are_you_sure: 'Apakah anda yakin?',
  successfully_rejected: 'Berhasil ditolak',
  name_user_id_email_phone: 'Nama, ID Pengguna, Email, no. Telepon',

  //member details
  full_name: 'Nama Lengkap',
  ktp_passport_number: 'No. KTP/Passport',
  address: 'Alamat',
  address_details: 'Alamat Detail',
  street_building_name_house_number_complete:
    'Jalan, Nama Gedung, Nomor Rumah (Lengkap)',
  country: 'Negara',
  province: 'Provinsi',
  city: 'Kota',
  sub_district: 'Kecamatan',
  zip_code: 'Kode Pos',
  scan_ktp_passport_has_not_been_uploaded: 'Scan KTP/Passport belum diupload',
  kk_scans_have_not_been_uploaded: 'Scan KK belum diupload',
  user_tree: 'User Tree',
  go_to_user_tree: 'Go To User Tree',
  account_holder_name: 'Nama Pemilik Rekening',
  account_number: 'No. Rekening',
  telephone_cellphone_number: 'No. Telepon/HP',
  membership_due_date: 'Membership Due Date',
  membership_payment_method: 'Metode Pembayaran Membership',
  autodebet_from_earning: 'Autodebet dari Earning',
  manual_bank_transfer: 'Transfer Bank Manual',
  saldo: 'Saldo',
  inject_balance: 'Inject Saldo',
  total_referral: 'Total Referral',
  remaining_star: `Sisa ${EPOINT_LABEL}`,
  inject_member_balance: 'Inject Saldo Member',
  balance_amount: 'Jumlah Saldo',
  user_id_generated: '* Akan di-generate otomatis oleh sistem',
  active_package_id:
    '* Dengan berbelanja ini, maka anda telah mendapatkan keanggotaan selama 1 tahun',
  sign_up: 'Daftar',
  user_policy: 'Kebijakan Pengguna',

  //change Pass
  new_password: 'Password Baru',
  repeat_new_password: 'Ulangi Password Baru',

  //transaction history
  transaction_type: 'Jenis Transaksi',
  debit: 'Debit',
  credit: 'Kredit',

  //point history
  username: 'Nama Pengguna',
  pairing: 'Pairing',
  remaining_point: `Sisa ${POINT_LABEL}`,
  inject_point: `Tambahkan ${POINT_LABEL}`,
  inject_point_member: `Tambahkan Poin ${POINT_LABEL}`,
  number_of_points: `Jumlah ${POINT_LABEL}`,
  position: 'Posisi',

  //rewards
  ranking_this_period: `Peringkat ${PERIOD_LABEL.id} Ini`,
  highest_ranking: 'Peringkat Tertinggi',
  ranking: 'Peringkat',
  cycle: `${CYCLE_LABEL}`,
  reward: 'Hadiah',
  date_reached: 'Tanggal Tercapai',
  action: 'Aksi',
  already_taken: 'Sudah diambil',
  not_yet_taken: 'Belum diambil',
  has_the_reward_been_given: 'Apakah reward sudah diberikan?',
  claim: 'Klaim',
  not_yet_obtained: 'Belum didapatkan',
  members_or_rewards: 'Anggota atau Hadiah',

  //cycle history
  inject_cycle: `Tambah ${CYCLE_LABEL}`,
  inject_cycle_member: `Tanbah ${CYCLE_LABEL} Anggota`,
  number_of_cycles: `Jumlah ${CYCLE_LABEL}`,
  cycle_history_per_period: `Riwayat ${CYCLE_LABEL} Per ${PERIOD_LABEL.id}`,
  period: 'Pekan',
  month: 'Bulan',
  year: 'Tahun',
  number_of_cycles_this_period: `Jumlah ${CYCLE_LABEL} ${PERIOD_LABEL.id} ini`,
  amount: 'Jumlah',
  injected_cycle: `${CYCLE_LABEL} sudah Ditambahkan`,

  //List referral
  sales_rank_s: `${SALES_RANK} S`,
  sales_rank_m: `${SALES_RANK} M`,
  sales_rank_l: `${SALES_RANK} L`,
  sales_rank_xl: `${SALES_RANK} XL`,
  active: 'Aktif',
  blocked: 'Diblokir',
  inactive: 'Non Aktif',
  ready: 'Ready',
  no_filter: 'No Filter',

  // tenden list referral
  sales_rank_beta: 'Beta Partner',
  sales_rank_regular: 'Regular Partner',
  filter_active: 'Active',
  filter_inactive: 'Inactive',
  filter_qualified: 'Qualified',
  filter_nonqualified: 'Non-Qualified',
  filter_left: 'Left',
  filter_right: 'Right',

  //bonusEvent
  bonus_amount: 'Jumlah Bonus',

  //invoices
  approved: 'Disetujui',
  category: 'Kategori',
  total_cost: 'Total Biaya',

  //stars
  star_entry: `${EPOINT_LABEL} Masuk`,
  star_out: `${EPOINT_LABEL} Keluar`,
  pending: 'Tertunda',
  request: 'Permintaan',
  confirmed: 'Dikonfirmasi',
  pending_top_up: 'Pending Top Up',
  inject_star: `Tambahkan ${EPOINT_LABEL}`,

  //star
  purchase_rejected: 'Pembelian ditolak',
  not_confirmed: 'Belum Dikonfirmasi',
  receive_star_purchases: `Terima Pembelian ${EPOINT_LABEL}?`,
  are_you_sure_you_want_to_accept_this_star_purchase: `Apakah Anda yakin ingin menerima pembelian ${EPOINT_LABEL} ini?`,
  reject_star_purchases: 'Tolak Pembelian Stpointar?',
  are_you_sure_you_want_to_reject_this_star_purchase: `Apakah Anda yakin ingin menolak pembelian ${EPOINT_LABEL} ini?`,
  confirm_purchase_of_star_package: `Konfirmasi Pembelian Paket ${EPOINT_LABEL}`,
  purchase_a_star_package: `Pembelian Paket ${EPOINT_LABEL}`,

  // order
  choose_product: 'Pilih Insole',
  choose_size: 'Pilih Ukuran',
  view_order_detail: 'Lihat Detail Order',
  product_detail: 'Detail Produk',
  shipment_detail: 'Info Pengiriman',
  payment_detail: 'Rincian Pembayaran',
  shipping_cost: 'Ongkos kirim',
  payment_confirmed: 'Konfirmasi',

  //withdrawal
  rejected: 'Ditolak',
  Withdraw: 'Tarik Saldo',
  there_is_no_balance: 'Tidak ada saldo',
  minimum_withdrawal: 'Minimal penarikan',
  enter_withdrawal_amount: 'Masukan Jumlah Penarikan',
  the_minimum_balance_to_withdraw_is_idr_100_000:
    'Minimal saldo untuk melakukan Penarikan yaitu Rp 100.000',
  every_withdrawal_is_subject_to_an_administration_fee_of_rp_7_500:
    `Setiap penarikan dikenakan biaya administrasi sebesar ${ADMIN_FEE}`,
  to_withdraw_you_must_upload_your_id_card:
    'Untuk melakukan Penarikan, Anda wajib untuk upload KTP Anda.',
  withdraw_will_be_disbursed_every_monday:
    'Penarikan akan dicairkan setiap hari Senin.',
  for_withdrawals_on_monday_withdrawals_must_be_submitted_no_later_than_sunday_if_withdraw_is_submitted_monday_then_withdraw_will_disbursed_on_the_following_monday:
    'Untuk pencairan di hari Senin, Penarikan harus diajukan paling lambat hari Minggu.Jika Penarikan diajukan hari Senin, maka Penarikan akan dicairkan pada hari Senin minggu berikutnya.',
  the_account_holder_s_name_must_be_the_same_as_the_name_of_the_registered_member_otherwise_withdrawals_cannot_be_withdrawn:
    'Nama pemilik rekening wajib sama dengan nama member yang terdaftar. Jika tidak, maka Penarikan tidak dapat dicairkan.',
  family_relationship:
    'Jika terdapat hubungan keluarga antara Pemilik Rekening Bank dan Anggota Terdaftar, penarikan dapat dilakukan dengan syarat bahwa mereka harus melampirkan Kartu Keluarga untuk menunjukkan hubungan tersebut.',
  account_holder_name_id: 'KTP Nama Pemilik Rekening',
  ktp_passport_registered_member_name: 'KTP/Paspor Nama Member Terdaftar',
  family_card_that_shows_a_family_relationship_between_the_account_holder_and_registered_member:
    'Kartu Keluarga yang menunjukkan adanya hubungan keluarga antara Pemilik Rekening dengan Member Terdaftar',
  nb_documents_must_be_sent_before_monday_if_documents_are_sent_on_monday_or_after_monday_withdrawals_will_be_withdrawn_on_the_following_monday:
    'NB: Dokumen wajib dikirimkan sebelum hari Senin, jika dokumen dikirimkan pada hari Senin atau setelah hari Senin, maka Penarikan akan dicairkan pada hari Senin berikutnya.',

  //administrationFee
  bank_account_number: 'Nomor Rekening Bank',
  administration_fee_history: 'Riwayat Biaya Administrasi',

  //rewardsHistory
  rewards_history: 'History Hadiah',
  unclaimed: 'Belum Diklaim',
  already_received: 'Sudah Diterima',
  achieve_time: 'Waktu Pencapaian',
  claim_time: 'Waktu klaim',

  //networkTree
  number_of_teams: 'Jumlah Team',
  search_by_id_or_name: 'Cari nama atau ID',
  clear: 'Clear',
  previous_tree: 'Previous Tree',
  assign_unassign_user: 'Kelola Jaringan User',
  quit_assign_unassign: 'Berhenti Kelola Jaringan User',
  unassigned_users: 'User tanpa jaringan',
  user_unassigned: 'User dikeluarkan',
  invalid_user_id: 'Invalid User Id',
  user_blocked: 'User diblokir',
  user_unblocked: 'User tidak diblokir',
  user_deleted: 'User dihapus',
  empty: 'Kosong',
  add_new: 'Buat Baru',
  unavailable: 'Tidak tersedia',
  confirm_unassign:
    'Apakah Anda yakin ingin mengeluarkan member ini dari network tree?',
  unassign: 'Keluarkan',
  confirm_block: 'Apakah Anda yakin ingin block member ini?',
  block: 'Blokir',
  confirn_unblock: 'Apakah Anda yakin ingin mengaktifkan member ini?',
  unblock: 'Buka blokir',
  confirm_delete: 'Apakah Anda yakin ingin menghapus member ini?',
  package_option: `${SALES_RANK} {{name}} {{price}} sudah termasuk {{star}} ${EPOINT_LABEL}`,
  payment_type_note:
    'Catatan: Pastikan data diri Anda telah diisi dengan benar. Data ini tidak dapat diubah kembali.',
  address_or_domicile: 'Alamat Tempat Tinggal/Domisili',

  //topearner
  earning: 'Pendapatan',

  //landingPageSettings
  header_section: 'Bagian Header',
  feature_section: 'Bagian Fitur',
  about_section: 'Bagian Tentang',
  quote_section: 'Bagian Kutipan',
  footer_section: 'Bagian Footer',
  videos: 'Video',
  add_header: 'Tambah Header',
  remove_header: 'Hapus Header',
  confirm_remove_header: 'Apakah anda yakin ingin menghapus header?',
  upload_new_header: 'Unggah Header Baru',
  change_header: 'Ubah Header',
  recommended_size_600px_x_400px: 'Recommended Size: 600px x 400px',
  img_size: 'Ukuran file gambar',
  banners: 'Banners',
  about_us: 'Tentang Kami',
  new_feature: 'Feature Baru',
  change_feature: 'Ubah Feature',
  quotes: 'Kutipan',
  quoted_by: 'Kutipan oleh',
  quote_background: 'Latar Kutipan',
  company: 'Perusahaan',
  web: 'Web',
  add_video: 'Tambah Video',
  edit_video: 'Ubah Video',
  video: 'Video',
  max_size_100_mb: 'Ukuran Maksimal : 100 Mb',
  add_new_banner: 'Tambah Banner',
  upload_new_banner: 'Unggah Banner Baru',
  edit_banner: 'Ubah Banner',
  delete_banner: 'Hapus Banner?',
  are_you_sure_you_want_to_delete_this_banner:
    'Apakah Anda yakin ingin menghapus banner ini?',
  yes_delete: 'Ya, Hapus!',
  delete_feature: 'Hapus Fitur',
  are_you_sure_you_want_to_delete_feature:
    'Apakah anda yakin ingin menghapus Feature?',
  add_feature: 'Tambah Fitur',
  image: 'Gambar',

  //update news
  create_news: 'Buat Berita',
  edit_news: 'Ubah Berita',
  add_news: 'Tambah Berita',
  add_image: 'Tambah Gambar',
  recommended_size_800_x_300_pixel: '*Saran ukuran : 800 x 300 pixel',
  title: 'Judul',
  news: 'Berita',
  add: 'Tambah',

  //downloadFile
  upload_document: 'Unggah Dokumen',
  edit_document: 'Ubah Dokumen',
  file_type: 'Jenis File',
  file_size: 'Ukuran File',

  //profil
  max_file_size_1_mb: 'Ukuran Maksimal File 1MB',
  kk_photo: 'Foto KK',
  KTP_photo: 'Foto KTP',
  optional_not_mandatory_max_file_size_of_1_mb:
    'Optional (Tidak Wajib), Ukuran Maksimal File 1MB',
  notes: 'Catatan',
  make_sure_your_data_has_been_filled_in_correctly_this_data_cannot_be_changed_again:
    'Pastikan data diri anda sudah diisi dengan benar. Data ini tidak dapat diubah kembali',
  upgrade_sales_ranking: `Tingkatkan ${SALES_RANK}`,
  confirmation_of_payment_for_sales_ranking_upgrade:
    `Konfirmasi Pembayaran Upgrade ${SALES_RANK}`,
  please_transfer_with_the_following_details:
    'Silahkan transfer dengan detail sebagai berikut',
  amount_of_transfer: 'Jumlah Transfer',
  transfer_amount_note: 'Cantumkan sesuai dengan nominal yang Anda transfer',
  if_you_have_transferred_please_confirm_payment:
    'Jika sudah transfer, silahkan konfirmasi pembayaran',
  sending_bank: 'Bank Pengirim',
  confirm_payment: 'Konfirmasi Pembayaran',
  successful_sales_ranking_upgrade: `Upgrade ${SALES_RANK} Berhasil`,
  your_sales_rank_will_change_after_being_confirmed_by_admin:
    `${SALES_RANK} Anda akan berubah setelah dikonfirmasi oleh Admin`,
  confirm_membership_payment: 'Konfirmasi Pembayaran Membership',
  change_password: 'Ganti Password',
  account_details: 'Detail Akun',
  payment_info: 'Info Pembayaran',
  old_password: 'Password Lama',
  required: 'Wajib diisi',
  incorrect_email_format: 'Format email salah',
  passwords_are_not_the_same: 'Password tidak sama',
  phone_number: 'No. HP',
  city_of_domicile: 'Kota Domisili',
  instagram_optional: 'Instagram (Optional)',
  join_us: 'Bergabunglah dengan kami!',
  expenditure: 'Pembelanjaan',

  //sales-rank
  price: 'Harga',
  limit_pairing: 'Limit Pairing',
  is_bussiness: 'Apakah Bisnis?',
  is_active: 'Apakah Aktif?',
  max_generation: 'Max. Generation',

  //manage Rewards
  start_date: 'Tanggal Mulai',
  expired_date: 'Tanggal kadaluarsa',
  create_at: 'Dibuat Pada',
  file: 'File',
  reward_deleted: 'Hadiah dihapus',
  minimum_sponsor_earned: 'Minimum Sponsor yang dihasilkan',
  sponsor_package: ' Paket Sponsor',
  rank: 'Peringkat',
  times: 'Jumlah',
  minimum_cycle_earned: `Minimum ${CYCLE_LABEL} yang diperoleh`,
  date_start: 'Tanggal Mulai',
  date_expiration: 'Tanggal Kadaluarsa',
  add_file: 'Add File',
  delete_data: 'Hapus Data',
  are_you_sure_you_want_to_delete_this_data:
    'Anda yakin ingin menghapus data ini?',

  //login
  sign_in: 'Masuk',
  log_in_as_member: 'Masuk Sebagai Anggota',
  password: 'Password',
  lupa_password: 'Lupa Password?',
  sign_inB: 'MASUK',
  login_gagal: 'Gagal Masuk',
  login: 'Masuk',

  //komisi
  all_commissions: 'Semua Komisi',
  referral_commission: REFERRAL_COMMISION_LABEL,
  cycle_commission: CYCLE_COMMISSIONS,
  referral_name: 'Nama Referal',
  amount_of_royalties: 'Jumlah Royalti',
  royalties_from: 'Royalti Dari',
  sponsor: 'Sponsor',

  //top up star form
  star_package: `Paket ${EPOINT_LABEL}`,
  tranfer_type: 'Metode Transfer',
  amount_that_should_be_paid: 'Jumlah yang harus di bayar',
  type_to_search_banks: 'Ketik untuk mencari bank...',
  cancel_confirm: 'Batal Konfirmasi',
  note_star_will_be_received_after_you_have_been_verified_by_admin: `*Note: ${EPOINT_LABEL} akan diterima setelah Anda diverifikasi oleh Admin`,
  topup_note:
    'Catatan: Konfirmasi pembayaran Anda, setelah pengajuan pembelian ini.',
}

export default id

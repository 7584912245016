import React from 'react'
import classnames from 'classnames'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const fontSize = Quill.import('attributors/style/size')
const defaultFontSize = 13
const fontSizes = [16, 18, 20, 24, 30]
fontSize.whitelist = [`${defaultFontSize}px`, ...fontSizes.map(s => `${s}px`)]
Quill.register(fontSize, true)

export interface TextEditorProps {
  wrapperClassName?: string
  data?: string
  onChange?: (content: string) => void
  invalid?: boolean
  readOnly?: boolean
}

const QuillCustomToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-font" />
    </span>
    <span className="ql-formats">
      <select
        className="ql-header"
        defaultValue={''}
        onChange={e => e.persist()}
      >
        <option value="1" />
        <option value="2" />
        <option selected />
      </select>
    </span>
    <span className="ql-formats">
      <select className="ql-size">
        <option selected>{defaultFontSize}</option>
        {fontSizes.map((size, index) => {
          return (
            <option key={index} value={`${size}px`}>
              {size}
            </option>
          )
        })}
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-blockquote" />
    </span>
    <span className="ql-formats">
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
    </span>
    <span className="ql-formats">
      <button className="ql-clean" />
    </span>
  </div>
)

const TextEditor = ({
  data,
  onChange,
  wrapperClassName,
  invalid,
  readOnly,
}: TextEditorProps) => {
  return (
    <div
      className={classnames([
        'editor-wrapper',
        wrapperClassName,
        invalid && 'invalid',
      ])}
    >
      {readOnly ? <div id="no-toolbar" /> : <QuillCustomToolbar />}
      <ReactQuill
        value={data}
        onChange={onChange}
        readOnly={readOnly}
        modules={{
          toolbar: {
            container: readOnly ? '#no-toolbar' : '#toolbar',
          },
        }}
        formats={[
          'header',
          'size',
          'bold',
          'italic',
          'underline',
          'color',
          'background',
          'font',
          'align',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
        ]}
        className={readOnly ? 'readonly' : ''}
      />
    </div>
  )
}

export default TextEditor

import classnames from 'classnames'
import React from 'react'
import { Spinner, SpinnerProps } from 'reactstrap'

interface LoadingSpinnerProps
  extends Pick<
    SpinnerProps,
    'type' | 'size' | 'color' | 'className' | 'cssModule' | 'children'
  > {
  centered?: boolean
  className?: string
}

const LoadingSpinner = ({
  centered,
  className,
  ...props
}: LoadingSpinnerProps) => {
  return (
    <div
      className={classnames(centered ? 'text-center' : '', className)}
      style={{ display: centered ? 'block' : 'inline-block' }}
    >
      <Spinner {...props} />
    </div>
  )
}

export default LoadingSpinner

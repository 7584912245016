import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createHttpLink } from '@apollo/client/link/http'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import React from 'react'
import ReactDOM from 'react-dom'
import { Slide, ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'
import Cookie from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './App'
import { UserProvider } from './contexts/UserContext'
import * as serviceWorker from './serviceWorker'
import { ROOT_URL, SENTRY_DSN } from './settings'
import { notifyError } from './shared/notifications'
import { browserHistory } from './Router'
import './utils/transformer/i18n'

const graphqlUri = `${ROOT_URL}/graphql`

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})


const httpLink = createHttpLink({ uri: graphqlUri })
const authLink = setContext((_, { headers }) => {
  const token = Cookie.get('userToken')
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      // localtunnel
      'Bypass-Tunnel-Reminder': '1'
    },
  }
})

const logoutLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const messages = graphQLErrors
      .map(err => err.message)
      .map(m => m.toLowerCase())
      .join(' ')

    if (messages.includes('user not signed in')) {
      Cookie.remove('userToken')
      notifyError('Session Expired')
      browserHistory.replace('/auth/login')
    }
  }
})

const uploadLink = createUploadLink({
  uri: graphqlUri,
})

export const client = new ApolloClient({
  link: authLink.concat(logoutLink).concat(uploadLink).concat(httpLink),
  cache: new InMemoryCache(),
})

const App = (props: any) => {
  return (
    <ApolloProvider client={client}>
      <ToastContainer
        hideProgressBar
        autoClose={1700}
        position="top-right"
        transition={Slide}
        toastClassName="mt-3"
      />
      <UserProvider>
        <Routes />
      </UserProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// @ts-ignore
if (module.hot /* && process.env.NODE_ENV !== 'production' */) {
  // @ts-ignore
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button, ButtonGroup } from 'reactstrap'

export const LanguageSwitcher = ({ color = 'white' }) => {
  const { i18n } = useTranslation()
  const { replace } = useHistory()

  function handleClickId() {
    i18n.changeLanguage('id')
    localStorage.setItem('lang', 'id')
    replace({})
  }

  function handleClickEn() {
    i18n.changeLanguage('en')
    localStorage.setItem('lang', 'en')
    replace({})
  }

  return (
    <ButtonGroup>
      <Button
        outline={i18n.language !== 'id'}
        color={color}
        className="py-0"
        onClick={handleClickId}
      >
        ID
      </Button>
      <Button
        outline={i18n.language !== 'en'}
        color={color}
        className="py-0"
        onClick={handleClickEn}
      >
        Eng
      </Button>
    </ButtonGroup>
  )
}

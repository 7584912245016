import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { NavLink, useHistory } from 'react-router-dom'
import EnlogyAva from '../../../assets/enlogy-ava.jpeg'
import UserContext from '../../../contexts/UserContext'
import { oc } from 'ts-optchain'
import useAccessRights from '../../../shared/hooks/useAccessRights'
import { useTranslation } from 'react-i18next'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import { FaDoorOpen, FaUserAlt, FaUserCircle } from 'react-icons/fa'
import { useProfileFormQuery } from '../../../generated/graphql'

const UserProfile = () => {
  const { t } = useTranslation()
  const { user, loading } = useContext(UserContext)
  const { isAllowed, roleNames } = useAccessRights(['superadmin', 'admin'])

  return (
    <div>
      <NavLink to="/account/profile" className="user-panel">
        <img src={EnlogyAva} alt="" className="user-image" />
        {loading ? (
          <div className="w-100">
            <Skeleton width="100%" height={8} />
            <Skeleton width="80%" height={8} />
          </div>
        ) : (
          <div>
            <div className="username quicksand-bold">{oc(user).name()}</div>
            <div className="subrole">
              {isAllowed ? roleNames.join(', ') : oc(user).package.name()}
            </div>
            <div className="small btn btn-success btn-sm mt-1">
              {t('account')}{' '}
            </div>
          </div>
        )}
      </NavLink>
    </div>
  )
}

export const UserProfileSmall = () => {
  const router = useHistory()
  const { data: profile } = useProfileFormQuery()
  const linkTo = (path: string) => () => router.push(path)

  return (
    <div>
      <UncontrolledDropdown>
        <DropdownToggle caret color="light">
          <FaUserCircle size="40px" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem disabled>{oc(profile).me.email('')}</DropdownItem>
          <DropdownItem onClick={linkTo('/account/profile')}>
            <FaUserAlt className="mr-2" />
            Profile Settings
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={linkTo('/logout')}>
            <FaDoorOpen className="mr-2" />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default UserProfile

import en from '../langs/en'
import id from '../langs/id'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: en,
  },
  id: {
    translation: id,
  },
}

const lang = localStorage.getItem('lang')

i18n.use(initReactI18next).init({
  resources,
  lng: lang || 'en',
  fallbackLng: 'id',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export const t = (key: any) => i18n.t(key)

export default i18n

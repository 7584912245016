import { ADMIN_FEE, BANK_ACCOUNT, BONUS_EVENT, CYCLE_COMMISSIONS, CYCLE_LABEL, EPOINT_LABEL, PERIOD_LABEL, POINT_LABEL, REFERRAL_COMMISION_LABEL, SALES_RANK } from '../../shared/constants'

const en = {
  //common
  close: 'Close',
  back: 'Back',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  search: 'Search',
  search_: 'Search...',
  date: 'Date',
  save: 'Save',
  change: 'Change',
  edit: 'Edit',
  delete: 'Delete',
  agree: 'Agree',
  //dashboard
  total_earning_member: 'Total Earning Member',
  total_member: 'Total Member',
  total_star: `Total ${EPOINT_LABEL}`,
  total_withdraw: 'Total Withdraw',
  total_member_small: 'Total Small Member',
  used_star: `Used ${EPOINT_LABEL}`,
  total_saldo_member: 'Total Balance Member',
  total_member_Medium: 'Total Medium Member',
  unused_star: `Unused ${EPOINT_LABEL}`,
  total_member_Large: 'Total Large Member',
  total_member_Extra_Large: 'Total Extra Large Member',
  current_status: 'Current Status',
  saldo_income: 'Saldo Income',
  total_income: 'Total Income',
  performa: 'The Performance',
  cycle_achieved: `${CYCLE_LABEL} Achieved`,
  this_period: `This ${PERIOD_LABEL.en}`,
  point: `${POINT_LABEL}`,
  left: 'Left',
  right: 'Right',
  active_member: 'Active Member',
  commission: 'Commission',
  active: 'Active',
  qualified: 'Qualified',
  this_year: 'This Year',
  referral_commision: REFERRAL_COMMISION_LABEL,
  cycle_commision: `${CYCLE_LABEL} Commision`,
  generation_residual_income: 'Generation Residual Income',
  bonus_event: BONUS_EVENT,
  total_commision: 'Total Commission',
  logout: 'Logout',
  star_usage: `${EPOINT_LABEL} Usage`,

  //sidebar
  order: 'Order',
  account: 'Account',
  main_navigation: 'Main Navigation',
  dashboard: 'Dashboard',
  member: 'Member',
  star: `${EPOINT_LABEL}`,
  withdrawal: 'Withdrawal',
  administration_fee: 'Administration Fee',
  rewards: 'Rewards',
  manage_rewards: 'Manage Rewards',
  network_tree: 'Network Tree',
  top_earner: 'Top Earner',
  landing_page_settings: 'Landing Page Settings',
  update_news: 'Update News',
  others: 'Others',
  sales_rank: `${SALES_RANK}`,
  download_file: 'Download File',
  my_referral: 'My Referral',
  royalty: 'Royalty',
  invoice: 'Invoice',

  //member
  stars: `${EPOINT_LABEL}s`,
  invoices: 'Invoices',
  list_referral: 'List Referral',
  cycle_history: `${CYCLE_LABEL} History`,
  point_history: 'Poin History',
  transaction_history: 'Transaction History',
  details_user: 'Details Users',
  list_member: 'List Member',
  new_member: 'New Member',
  upgraded_member: 'Upgraded Member',
  membership: 'Membership',
  join_date: 'Join Date',
  name: 'Name',
  user_id: 'User ID',
  phone: 'Phone',
  email: 'Email',
  status: 'Status',
  order_status: 'Order Status',
  payment_status: 'Payment Status',
  transaction_id: 'Transaction ID',
  note: 'Description',
  total_transfers: 'Total Transfers',
  payment_details: 'Payment Details',
  accept: 'Accept',
  reject: 'Reject',
  new_member_registration_sales_ranking: `New Member`,
  amount_of_money_to_be_paid: 'Amount of Money to be Paid',
  amount_of_money_sent: 'Amount of Money Sent',
  transfer_to: 'Transfer to',
  company_transfer_account: BANK_ACCOUNT.replace(' a.n. ', ' on behalf '),
  name_of_the_sender: 'Name of The Sender',
  sending_via: 'Sending Via',
  accept_member: 'Accept Member',
  are_you_sure_you_want_to_accept_this_person_as_a_member:
    'Are you sure you want to accept this person as a member?',
  reject_member: 'Reject Member',
  are_you_sure_you_want_to_reject_this_person_as_a_member:
    'Are you sure you want to reject this person as a member?',
  upgrade_from: 'Upgrade From',
  confirm_upgrade_payment: 'Confirm Upgrade Payment',
  confirm_new_member_payment: 'Confirm New Member Payment',
  show_entries: 'Show Entries',
  accepted: 'Accepted',
  membership_payments: 'Membership Payments',
  are_you_sure: 'Are You Sure?',
  successfully_rejected: 'Successfully Rejected',
  name_user_id_email_phone: 'Name, User ID, Email, Phone',

  //member details
  full_name: 'Full Name',
  ktp_passport_number: 'ID Card/Passport Number',
  address: 'Address',
  address_details: 'Details Address',
  street_building_name_house_number_complete:
    'Street, Building name, House Number (Complete)',
  country: 'Country',
  province: 'Province',
  city: 'City',
  sub_district: 'Sub District',
  zip_code: 'Postal Code',
  scan_ktp_passport_has_not_been_uploaded:
    'Scan KTP/Passport has not been uploaded',
  kk_scans_have_not_been_uploaded: 'Scan KK has not been uploaded',
  user_tree: 'User Tree',
  go_to_user_tree: 'Go To User Tree',
  account_holder_name: 'Account Holder Name',
  account_number: 'Account Number',
  telephone_cellphone_number: 'Telephone/HP Number',
  membership_due_date: 'Membership Due Date',
  membership_payment_method: 'Membership Payment Method',
  autodebet_from_earning: 'Autodebet from Earning',
  manual_bank_transfer: 'Manual Bank Transfer',
  saldo: 'Balance',
  inject_balance: 'Inject Balance',
  total_referral: 'Total Referral',
  remaining_star: `Remaining ${EPOINT_LABEL}`,
  inject_member_balance: 'Inject Member Balance',
  balance_amount: 'Balance Amount',
  user_id_generated: '* Generated by system',
  active_package_id: '* By purchasing this, you will get membership for a year',
  sign_up: 'Sign Up',
  user_policy: 'User Policy',

  //change Pass
  new_password: 'New Password',
  repeat_new_password: 'Repeat New Password',

  //transaction history
  transaction_type: 'Transaction Type',
  debit: 'Debit',
  credit: 'Credit',

  //point history
  username: 'Username',
  pairing: 'Pairing',
  remaining_point: `Remaining ${POINT_LABEL}`,
  inject_point: `Inject ${POINT_LABEL}`,
  inject_point_member: `Inject ${POINT_LABEL} Member`,
  number_of_points: `Number of ${POINT_LABEL}s`,
  position: 'Position',

  //rewards
  ranking_this_period: `Rank This ${PERIOD_LABEL.en}`,
  highest_ranking: 'Highest Rank',
  ranking: 'Rank',
  cycle: `${CYCLE_LABEL}`,
  reward: 'Reward',
  date_reached: 'Date Reached',
  action: 'Action',
  already_taken: 'Already taken',
  not_yet_taken: 'Not yet taken',
  has_the_reward_been_given: 'Has the reward been given?',
  claim: 'Claim',
  not_yet_obtained: 'Not yet obtained',
  members_or_rewards: 'Members or rewards',

  //cycle history
  inject_cycle: `Inject ${CYCLE_LABEL}`,
  inject_cycle_member: `Inject Member ${CYCLE_LABEL} `,
  number_of_cycles: `Number of ${CYCLE_LABEL}s`,
  cycle_history_per_period: `${CYCLE_LABEL} History Per ${PERIOD_LABEL.en}`,
  period: `${PERIOD_LABEL.en}`,
  month: 'Month',
  year: 'Year',
  number_of_cycles_this_period: `Number of ${CYCLE_LABEL}s This ${PERIOD_LABEL.en}`,
  amount: 'Amount',
  injected_cycle: `Injected ${CYCLE_LABEL}`,

  //List referral
  sales_rank_s: `${SALES_RANK} S`,
  sales_rank_m: `${SALES_RANK} M`,
  sales_rank_l: `${SALES_RANK} L`,
  sales_rank_xl: `${SALES_RANK} XL`,
  blocked: 'Blocked',
  inactive: 'Inactive',
  ready: 'Ready',
  no_filter: 'No Filter',

  // tenden list referral
  sales_rank_beta: 'Beta Partner',
  sales_rank_regular: 'Regular Partner',
  filter_active: 'Active',
  filter_inactive: 'Inactive',
  filter_qualified: 'Qualified',
  filter_nonqualified: 'Non-Qualified',
  filter_left: 'Left',
  filter_right: 'Right',

  //bonusEvent
  bonus_amount: 'Bonus Amount',

  //invoices
  approved: 'Approved',
  category: 'Category',
  total_cost: 'Total Cost',

  //stars
  star_entry: `${EPOINT_LABEL} Entry`,
  star_out: `${EPOINT_LABEL} Out`,
  pending: 'Pending',
  request: 'Request',
  confirmed: 'Confirmed',
  pending_top_up: 'Pending Top Up',
  inject_star: `Inject ${EPOINT_LABEL}`,

  //star
  purchase_rejected: 'Purchase Rejected',
  not_confirmed: 'Not Confirmed',
  receive_star_purchases: `Accept ${EPOINT_LABEL} Purchases?`,
  are_you_sure_you_want_to_accept_this_star_purchase: `Are you sure you want to accept this ${EPOINT_LABEL} purchase?`,
  reject_star_purchases: `Reject ${EPOINT_LABEL} Purchases?`,
  are_you_sure_you_want_to_reject_this_star_purchase: `Are you sure you want to reject this ${EPOINT_LABEL} purchase?`,
  confirm_purchase_of_star_package: `Confirm Purchase of ${EPOINT_LABEL} Package`,
  purchase_a_star_package: `Purchase a ${EPOINT_LABEL} Package`,

  // order
  choose_product: 'Choose Insole',
  choose_size: 'Choose Size',
  view_order_detail: 'View Order Detail',
  product_detail: 'Product Detail',
  shipment_detail: 'Shipment Detail',
  payment_detail: 'Payment Detail',
  shipping_cost: 'Shipping Cost',
  payment_confirmed: 'Confirmed',

  //withdrawal
  rejected: 'Rejected',
  Withdraw: 'Withdraw',
  there_is_no_balance: 'There is no Balance',
  minimum_withdrawal: 'Minimum Withdrawal',
  enter_withdrawal_amount: 'Enter withdrawal amount',
  the_minimum_balance_to_withdraw_is_idr_100_000:
    'The minimum balance to withdraw is IDR 100.000',
  every_withdrawal_is_subject_to_an_administration_fee_of_rp_7_500:
    `Every withdrawal is subject to an administration fee of ${ADMIN_FEE}`,
  to_withdraw_you_must_upload_your_id_card:
    'To withdraw, you must upload your ID card.',
  withdraw_will_be_disbursed_every_monday:
    'Withdraw will be disbursed every Monday.',
  for_withdrawals_on_monday_withdrawals_must_be_submitted_no_later_than_sunday_if_withdraw_is_submitted_monday_then_withdraw_will_disbursed_on_the_following_monday:
    'For withdrawals on Monday, withdrawals must be submitted no later than Sunday. If withdrawals are filed on Monday, withdrawals will be disbursed on the following Monday.',
  the_account_holder_s_name_must_be_the_same_as_the_name_of_the_registered_member_otherwise_withdrawals_cannot_be_withdrawn:
    'The account holder name must be the same as the name of the registered member. Otherwise, withdrawals cannot be withdrawn.',
  family_relationship:
    'If there is a family relationship between the Bank Account Owner and Registered Member, withdrawals can be withdrawn on condition that they have to attach Family Card to show the relation in between.',
  account_holder_name_id: 'KTP of Account Holder',
  ktp_passport_registered_member_name: 'KTP/Passport of Registered Member',
  family_card_that_shows_a_family_relationship_between_the_account_holder_and_registered_member:
    'Family Card that shows a family relationship between the Account Holder and Registered Member',
  nb_documents_must_be_sent_before_monday_if_documents_are_sent_on_monday_or_after_monday_withdrawals_will_be_withdrawn_on_the_following_monday:
    'NB: Documents must be sent before Monday, if documents are sent on Monday or after Monday, withdrawals will be withdrawn on the following Monday.',

  //administrationFee
  bank_account_number: 'Bank Account Number',
  administration_fee_history: 'Administration Fee History',

  //rewardsHistory
  rewards_history: 'Rewards History',
  unclaimed: 'Unclaimed',
  already_received: 'Already Received',
  achieve_time: 'Achieve time',
  claim_time: 'Claim time',

  //networkTree
  number_of_teams: 'Number of Teams',
  search_by_id_or_name: 'Search by ID or name',
  clear: 'Clear',
  previous_tree: 'Previous Tree',
  assign_unassign_user: 'Assign/Unassign User',
  quit_assign_unassign: 'Quit Assign/Unassign',
  unassigned_users: 'Unassigned Users',
  user_unassigned: 'User Unassigned',
  invalid_user_id: 'Invalid User Id',
  user_blocked: 'User Blocked',
  user_unblocked: 'User Unblocked',
  user_deleted: 'User Deleted',
  empty: 'Empty',
  add_new: 'Add New',
  unavailable: 'Unavailable',
  confirm_unassign: 'Are you sure to unassign this user?',
  unassign: 'Unassign',
  confirm_block: 'Are you sure to block this user?',
  block: 'Block',
  confirm_unblock: 'Are you sure to unblock this user?',
  unblock: 'Unblock',
  confirm_delete: 'Are you sure to delete this user?',
  package_option: `${SALES_RANK} {{name}} {{price}}, {{star}} ${EPOINT_LABEL} included`,
  payment_type_note:
    'Note: Make sure your data is correct. This data cannot be changed',
  address_or_domicile: 'Address/Domicile',

  //topearner
  earning: 'Earning',

  //landingPageSettings
  header_section: 'Header Section',
  feature_section: 'Feature Section',
  about_section: 'About Section',
  quote_section: 'Quote Section',
  footer_section: 'Footer Section',
  videos: 'Videos',
  add_header: 'Add Header',
  remove_header: 'Remove Header',
  confirm_remove_header: 'Are you sure to remove this header?',
  upload_new_header: 'Upload New Header',
  change_header: 'Change Header',
  recommended_size_600px_x_400px: 'Recommended Size: 600px x 400px',
  img_size: 'Img Size',
  banners: 'Banners',
  about_us: 'About Us',
  new_feature: 'New Feature',
  change_feature: 'Change Feature',
  quotes: 'Quotes',
  quoted_by: 'Quoted By',
  quote_background: 'Quote Background',
  company: 'Company',
  web: 'Web',
  add_video: 'Add Video',
  edit_video: 'Edit Video',
  video: 'Video',
  max_size_100_mb: 'Max size : 100 Mb',
  add_new_banner: 'Add New Banner',
  upload_new_banner: 'Upload New Banner',
  edit_banner: 'Edit Banner',
  delete_banner: 'Delete Banner?',
  are_you_sure_you_want_to_delete_this_banner:
    'Are you sure you want to delete this banner?',
  yes_delete: 'Yes, Delete!',
  delete_feature: 'Delete Feature',
  are_you_sure_you_want_to_delete_feature:
    'Are you sure you want to delete Feature ?',
  add_feature: 'Add Feature',
  image: 'Image',

  //update news
  create_news: 'Create News',
  edit_news: 'Edit News',
  add_news: 'Add News',
  add_image: 'Add Image',
  recommended_size_800_x_300_pixel: '*Recommended size : 800 x 300 pixel',
  title: 'Title',
  news: 'News',
  add: 'Add',

  //downloadFile
  upload_document: 'Upload Document',
  edit_document: 'Edit Document',
  file_type: 'File Type',
  file_size: 'File Size',

  //profil
  max_file_size_1_mb: 'Max file size 1MB',
  kk_photo: 'Family Card Photo',
  KTP_photo: 'ID Card Photo',
  optional_not_mandatory_max_file_size_of_1_mb:
    'Optional (not Required), Max file size 1MB',
  notes: 'Notes',
  make_sure_your_data_has_been_filled_in_correctly_this_data_cannot_be_changed_again:
    'Make sure your data has been filled in correctly. This data cannot be changed again',
  upgrade_sales_ranking: 'Upgrade Package',
  confirmation_of_payment_for_sales_ranking_upgrade:
    `Confirmation of Payment for ${SALES_RANK} Upgrade`,
  please_transfer_with_the_following_details:
    'Please transfer with the following details',
  amount_of_transfer: 'Amount of Transfer',
  transfer_amount_note: 'Put in accordance to the amount you transfer',
  if_you_have_transferred_please_confirm_payment:
    'If you have transferred, please confirm payment',
  sending_bank: 'Sending Bank',
  confirm_payment: 'Confirm Payment',
  successful_sales_ranking_upgrade: `Successful ${SALES_RANK} Upgrade`,
  your_sales_rank_will_change_after_being_confirmed_by_admin:
    `Your ${SALES_RANK} will change after being confirmed by Admin`,
  confirm_membership_payment: 'Confirm Membership Payment',
  change_password: 'Change Password',
  account_details: 'Account Details',
  payment_info: 'Payment Info',
  old_password: 'Old Password',
  phone_number: 'Phone Number',
  city_of_domicile: 'City of Domicile',
  instagram_optional: 'Instagram (Optional)',
  join_us: 'Join Us!',
  required: 'Required',
  incorrect_email_format: 'Incorrect email format',
  passwords_are_not_the_same: 'Passwords are not the same',
  expenditure: 'Expenditure',

  //sales-rank
  price: 'Price',
  limit_pairing: 'Limit Pairing',
  is_bussiness: 'Is Bussiness?',
  is_active: 'Is Active?',
  max_generation: 'Max. Generation',

  //manage Rewards
  start_date: 'Start Date',
  expired_date: 'Expired Date',
  create_at: 'Create At',
  file: 'File',
  reward_deleted: 'Reward dihapus',
  minimum_sponsor_earned: 'Minimum Sponsor Earned',
  sponsor_package: 'Sponsor Package',
  rank: 'Rank',
  times: 'Times',
  minimum_cycle_earned: `Minimum ${CYCLE_LABEL} Earned`,
  date_start: 'Date Start',
  date_expiration: 'Date Expiration',
  add_file: 'Add File',
  delete_data: 'Delete Data',
  are_you_sure_you_want_to_delete_this_data:
    'Are you sure you want to delete this data?',

  //login
  sign_in: 'Sign In',
  log_in_as_member: 'Log In as Member',
  password: 'Password',
  lupa_password: 'Forgot the password?',
  sign_inB: 'SIGN IN',
  login_gagal: 'Login Failed',
  login: 'Login',

  //komisi
  all_commissions: 'All Commissions',
  referral_commission: REFERRAL_COMMISION_LABEL,
  cycle_commission: CYCLE_COMMISSIONS,
  referral_name: 'Referral Name',
  amount_of_royalties: 'Amount of Royalties',
  royalties_from: 'Royalties From',
  sponsor: 'Sponsor',

  //top up star form
  star_package: `${EPOINT_LABEL} Package`,
  tranfer_type: 'Transfer Method',
  amount_that_should_be_paid: 'Amount That Shoud be Paid',
  type_to_search_banks: 'Type to search banks...',
  cancel_confirm: 'Cancel Confirmation',
  note_star_will_be_received_after_you_have_been_verified_by_admin: `*Note: ${EPOINT_LABEL} will be received after you have been verified by admin`,
  topup_note: 'Notes: Confirm your payment, after this purchase.',
}

export default en

import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router'
import Cookie from 'js-cookie'

import ApplicationLayout from '../../containers/application_layout'
import useAccessRights from '../hooks/useAccessRights'
import NotFound from '../../containers/not_found/NotFound'

const AuthRoute = ({ component, roles, ...props }: AuthRouteProps) => {
  const Component = component
  const { isAllowed, loading } = useAccessRights(roles || [])
  const token = Cookie.get('userToken')

  if (!isAllowed && !loading) return <NotFound />

  if (!token) return <Redirect to="/auth/login" />

  return (
    <Route
      {...props}
      render={routeProps => {
        return (
          <ApplicationLayout match={routeProps.match}>
            {Component && <Component {...routeProps} />}
          </ApplicationLayout>
        )
      }}
    />
  )
}

export default AuthRoute

export interface AuthRouteProps extends RouteProps {
  roles?: string[] | ('superadmin' | 'admin' | 'member')[]
}

import React from 'react'
import {
  FaEnvelope,
  FaGlobeAsia,
  FaMapMarker,
  FaBuilding,
  FaPhone,
} from 'react-icons/fa'
import { Col, Container, Row } from 'reactstrap'
import AppLogo from '../../assets/app_logo.png'
import BgFooter from '../../assets/footer.png'
import {
  FooterContentQueryHookResult,
  useFooterContentQuery,
} from '../../generated/graphql'
import { oc } from 'ts-optchain'

export interface Footer {
  company: { value?: string | null; isActive?: boolean | null }
  email: { value?: string | null; isActive?: boolean | null }
  address: { value?: string | null; isActive?: boolean | null }
  web: { value?: string | null; isActive?: boolean | null }
  phone: { value?: string | null; isActive?: boolean | null }
}

const Footer = () => {
  const { data }: FooterContentQueryHookResult = useFooterContentQuery()
  const footerJson = data && data.footer && data.footer[0].description
  const footer: Footer | null = footerJson
    ? JSON.parse(String(footerJson))
    : null

  // return <div className="pt-3" />

  return (
    <Container
      fluid
      className="footer"
      style={{
        background: `url(${BgFooter}) no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <Container>
        <Row className="p-md-5 p-3">
          <Col sm={12} md={8} className="d-flex align-items-center">
            {/* <img src={AppLogo} alt="" className="footer__logo" /> */}
          </Col>
          <Col sm={12} md={4} className="text-white">
            {oc(footer).company.isActive() && (
              <div className="d-flex">
                <span>
                  <FaBuilding className="mr-3" />
                </span>
                <span>{oc(footer).company.value()}</span>
              </div>
            )}

            {oc(footer).email.isActive() && (
              <div className="d-flex">
                <span>
                  <FaEnvelope className="mr-3" />
                </span>
                <span>{oc(footer).email.value()}</span>
              </div>
            )}

            {oc(footer).address.isActive() && (
              <div className="d-flex">
                <span>
                  <FaMapMarker className="mr-3" />
                </span>
                <span>{oc(footer).address.value()}</span>
              </div>
            )}

            {oc(footer).web.isActive() && (
              <div className="d-flex">
                <a href={window.location.href} className="link link-green">
                  <span>
                    <FaGlobeAsia className="mr-3" />
                  </span>
                  <span>{oc(footer).web.value()}</span>
                </a>
              </div>
            )}

            {oc(footer).phone.isActive() && (
              <div className="d-flex">
                <span>
                  <FaPhone className="mr-3" />
                </span>
                <span>{oc(footer).phone.value()}</span>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Footer
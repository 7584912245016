import React, { ReactNode, Fragment } from 'react'
import {
  Modal as BsModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps as BsModalProps,
  ModalHeaderProps,
} from 'reactstrap'
import i18n from 'i18next'
import classnames from 'classnames'
import Button from './Button'

export interface ModalProps extends BsModalProps {
  title?: string
  toggle?: ModalHeaderProps['toggle']
  footer?: ReactNode
  transparent?: boolean
  noMaxWidth?: boolean
}

const Modal = ({
  title,
  className,
  toggle,
  transparent,
  noMaxWidth,
  ...props
}: ModalProps) => {
  return (
    <BsModal
      centered
      {...props}
      className={classnames(
        'modal-enlogy',
        transparent && 'modal-transparent',
        noMaxWidth && 'no-max-width',
        className
      )}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
    </BsModal>
  )
}

type onClick = ((e: React.MouseEvent<any, MouseEvent>) => void) | undefined

export interface ModalDialogProps extends ModalProps {
  message?: ReactNode
  actions?: { yes?: string; no?: string }
  functions?: { onYes?: onClick; onNo?: onClick }
  disabled?: boolean
  loading?: boolean
}

const ModalDialog = ({
  message,
  actions = { yes: i18n.t('yes'), no: i18n.t('no') },
  functions = {},
  disabled,
  loading,
  ...props
}: ModalDialogProps) => {
  return (
    <Modal
      {...props}
      footer={
        <Fragment>
          <Button
            outline
            color="danger"
            onClick={functions.onNo}
            disabled={disabled || loading}
          >
            {actions.no}
          </Button>
          <Button
            outline
            color="primary"
            onClick={functions.onYes}
            disabled={disabled}
            loading={loading}
          >
            {actions.yes}
          </Button>
        </Fragment>
      }
    >
      {message}
    </Modal>
  )
}

export default Modal
export { ModalDialog }

import React, { ReactNode } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { IconType } from 'react-icons/lib/cjs'

interface MenuItemProps extends NavLinkProps {
  to: string
  icon: string | IconType | any
  children: ReactNode
}

const MenuItem = ({ to, icon, children, ...props }: MenuItemProps) => {
  return (
    <li>
      <NavLink
        to={to}
        className="enlogy-menu"
        activeClassName="active"
        {...props}
      >
        {typeof icon === 'string' && (
          <img src={icon} alt="" className="sidebar__menu-icon" />
        )}

        {typeof icon === 'function' &&
          icon({ className: 'sidebar__menu-icon' })}

        {typeof icon === 'object' && icon}
        <span className="sidebar__menu-name">{children}</span>
      </NavLink>
    </li>
  )
}

export default MenuItem

export const EPOINT_LABEL = 'Insole'
export const POINT_LABEL = 'Value'
export const CYCLE_LABEL = 'Star'
export const CYCLE_COMMISSIONS = 'Value & Shopping Bonus'
export const BONUS_EVENT = 'Star Bonus'
export const PERIOD_LABEL = {
  en: 'Month',
  id: 'Bulan'
}
export const REFERRAL_COMMISION_LABEL = 'Instant Bonus'

export const transferTypes = [
  { value: 'atm', label: 'Transfer ATM' },
  { value: 'e_banking', label: 'E Banking' },
  { value: 'm_banking', label: 'Mobile Banking' },
  { value: 'setor_tunai', label: 'Setoran Tunai' },
]

export const WITHDRAWAL_ADM_COST = 10000
export const MIN_WITHDRAWAL = 100000
export const PRODUCT_LABEL = 'Insole'
export const COMMISSION_VALUE_DIVIDER = 200000
export const ADMIN_FEE = 'Rp 10.000'
export const SALES_RANK = 'Member Status'

export const PRODUCTS = [
  { value: 1, label: `1 ${PRODUCT_LABEL}`, price: 500000 },
  { value: 2, label: `2 ${PRODUCT_LABEL}`, price: 1000000 },
  { value: 3, label: `3 ${PRODUCT_LABEL}`, price: 1500000 },
  { value: 4, label: `4 ${PRODUCT_LABEL}`, price: 2000000 },
  { value: 5, label: `5 ${PRODUCT_LABEL}`, price: 2500000 },
  { value: 6, label: `6 ${PRODUCT_LABEL}`, price: 3000000 },
  { value: 7, label: `7 ${PRODUCT_LABEL}`, price: 3500000 },
  { value: 8, label: `8 ${PRODUCT_LABEL}`, price: 4000000 },
  { value: 9, label: `9 ${PRODUCT_LABEL}`, price: 4500000 },
  { value: 10, label: `10 ${PRODUCT_LABEL}`, price: 5000000 },
]

export const getProductById = (id: number | string) => {
  return PRODUCTS.find((product) => product.value == id)
}

export const SIZES = [
  { label: 'S', value: 'S' },
  { label: 'M', value: 'M' },
  { label: 'L', value: 'L' },
  // { label: 'XL', value: 'XL' },
]

export const ORDER_DELIVER_STATUS = {
  PENDING: { value: 'PENDING', label: 'PENDING' },
  READY_TO_SHIP: { value: 'READY_TO_SHIP', label: 'READY TO SHIP' },
  DELIVERED: { value: 'DELIVERED', label: 'SHIPPED' },
}

export const BANK_ACCOUNT = 'BCA 5485-960-900 a.n. PT Tenden Karya Nusantara'
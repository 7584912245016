import React from 'react'
import { Button as BsButton, ButtonProps as BsButtonProps } from 'reactstrap'
import LoadingSpinner from './LoadingSpinner'

export interface ButtonProps extends BsButtonProps {
  loading?: boolean
}

const Button = ({ loading, ...props }: ButtonProps) => {
  return (
    <BsButton {...props} disabled={props.disabled || loading}>
      {props.children}
      {loading && <LoadingSpinner className="ml-2" size="sm" />}
    </BsButton>
  )
}

export default Button

import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import AuthRoute, { AuthRouteProps } from './shared/components/AuthRoute'
import ScrollToTop from './shared/components/ScrollToTop'
import useAccessRights from './shared/hooks/useAccessRights'
import loadable from '@loadable/component'
import { PageLoader } from './shared/components/PageLoader'

const lazyload = (fn: any) =>
  loadable(fn, {
    fallback: <PageLoader />,
  })

const Homepage = lazyload(() => import('./containers/homepage/HomePage'))
const LogoutPage = lazyload(() => import('./containers/logout/LogoutPage'))
const ProfilePage = lazyload(() => import('./containers/accounts/ProfilePage'))
const DashboardPage = lazyload(
  () => import('./containers/dashboard/DashboardPage')
)
const MembersPage = lazyload(() => import('./containers/members/MembersPage'))
const LandingSettingsPage = lazyload(
  () => import('./containers/landing_settings/LandingSettingsPage')
)
const NewsPage = lazyload(() => import('./containers/news/NewsPage'))
const OthersPage = lazyload(() => import('./containers/others/OthersPage'))
const EditOthersPage = lazyload(
  () => import('./containers/others/EditOthersPage')
)
const CreateOthersPage = lazyload(
  () => import('./containers/others/CreateOthersPage')
)
const FilesPage = lazyload(() => import('./containers/files/FilesPage'))
const NetworkTreePage = lazyload(
  () => import('./containers/network_tree/NetworkTreePage')
)
const MemberDetailsPage = lazyload(
  () => import('./containers/members/MemberDetailsPage')
)
const PackagesPage = lazyload(
  () => import('./containers/packages/PackagesPage')
)
const CreateNewMemberPage = lazyload(
  () => import('./containers/network_tree/CreateNewMemberPage')
)
const CommissionsPage = lazyload(
  () => import('./containers/commissions/CommissionsPage')
)
// const ListStarPage = lazyload(
//   () => import('./containers/dashboard/ListStarPage')
// )
// const ListStarAdminPage = lazyload(
//   () => import('./containers/star/ListStarAdminPage')
// )
const ListManageRewardsPage = lazyload(
  () => import('./containers/manage_rewards/ListManageRewardsPage')
)
const EditRewardPage = lazyload(
  () => import('./containers/manage_rewards/EditRewardPage')
)
const CreateRewardPage = lazyload(
  () => import('./containers/manage_rewards/CreateRewardPage')
)
const ListReferralPage = lazyload(
  () => import('./containers/referral/ListReferralPage')
)
const RewardPage = lazyload(() => import('./containers/reward/RewardPage'))
const WithdrawalPage = lazyload(
  () => import('./containers/withdrawal/WithdrawalPage')
)
const InvoicePage = lazyload(() => import('./containers/invoices/InvoicePage'))
const AdministrationFeePage = lazyload(
  () => import('./containers/administration_fee/AdministrationFeePage')
)
const TopEarnerPage = lazyload(
  () => import('./containers/top_earner/TopEarnerPage')
)
const RewardsHistoryPage = lazyload(
  () => import('./containers/RewardsHistoryPage')
)
const ForgotPasswordPage = lazyload(
  () => import('./containers/auth/forgot_password/ForgotPasswordPage')
)
const ResetPasswordPage = lazyload(
  () => import('./containers/auth/reset_password/ResetPasswordPage')
)
const ListOrderPage = lazyload(
  () => import('./containers/orders/ListOrderPage')
)
const UpdatePackage = lazyload(
  () => import('./containers/packages/UpdatePackage')
)
const NotFound = lazyload(() => import('./containers/not_found/NotFound'))
const NewsContents = lazyload(
  () => import('./containers/dashboard/components/NewsContents')
)
const LoginPage = lazyload(() => import('./containers/auth/login'))
const ListOrderAdminPage = lazyload(
  () => import('./containers/star/ListOrderAdminPage')
)
const OrderDetailPage = lazyload(
  () => import('./containers/orders/OrderDetailPage')
)

export const browserHistory = createBrowserHistory()

const AdminRoute = (props: AuthRouteProps) => {
  return <AuthRoute {...props} roles={['superadmin', 'admin']} />
}

const MemberRoute = (props: AuthRouteProps) => {
  return <AuthRoute {...props} roles={['member']} />
}

const AppRouter = () => {
  const isAdmin = window.location.hostname.split('.').length > 2
  const { isAllowed: isMember } = useAccessRights(['member'])

  return (
    <Router history={browserHistory}>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={isAdmin ? LoginPage : Homepage} />
          <Route path="/auth/login" component={LoginPage} />
          <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
          <Route path="/auth/reset-password" component={ResetPasswordPage} />
          <Route exact path="/logout" component={LogoutPage} />
          <AuthRoute path="/account/profile" component={ProfilePage} />
          <AuthRoute
            exact
            path="/order/:id/detail"
            component={OrderDetailPage}
          />
          <AuthRoute
            path="/order"
            component={isMember ? ListOrderPage : ListOrderAdminPage}
          />
          <AuthRoute exact path="/dashboard" component={DashboardPage} />
          <AuthRoute
            path="/network-tree/add/:parentId/:side/"
            component={CreateNewMemberPage}
          />
          <AuthRoute path="/network-tree/:id?" component={NetworkTreePage} />
          <AuthRoute exact path="/withdrawal" component={WithdrawalPage} />
          <AuthRoute path="/files" component={FilesPage} />
          <AuthRoute path="/royalty" component={CommissionsPage} />
          <Redirect
            exact
            from="/landing-settings"
            to="/landing-settings/header"
          />
          <MemberRoute
            exact
            path="/dashboard/news/:id"
            component={NewsContents}
          />
          <MemberRoute path="/team" component={ListReferralPage} />
          <MemberRoute exact path="/reward" component={RewardPage} />
          <MemberRoute path="/invoice" component={InvoicePage} />
          <AdminRoute path="/member/:id/edit" component={MemberDetailsPage} />
          <AdminRoute path="/member" component={MembersPage} />
          <AdminRoute
            exact
            path="/rewards-history"
            component={RewardsHistoryPage}
          />
          <AdminRoute
            exact
            path="/transaction"
            component={AdministrationFeePage}
          />
          {/* <AdminRoute exact path="/star" component={ListStarAdminPage} /> */}
          <AdminRoute exact path="/star" component={ListOrderAdminPage} />
          <AdminRoute exact path="/top-earner" component={TopEarnerPage} />
          <AdminRoute path="/news" component={NewsPage} />
          <AdminRoute exact path="/others" component={OthersPage} />
          <AdminRoute
            exact
            path="/others/:id/edit"
            component={EditOthersPage}
          />
          <AdminRoute
            exact
            path="/others/create"
            component={CreateOthersPage}
          />
          <AuthRoute
            exact
            path="/sales-ranks"
            component={PackagesPage}
            roles={['superadmin']}
          />
          <AuthRoute
            exact
            path="/sales-ranks/:id/edit"
            component={UpdatePackage}
            roles={['superadmin']}
          />
          <AuthRoute
            exact
            path="/manage-rewards"
            component={ListManageRewardsPage}
            roles={['superadmin']}
          />
          <AuthRoute
            exact
            path="/manage-rewards/:id/edit"
            component={EditRewardPage}
            roles={['superadmin']}
          />
          <AuthRoute
            exact
            path="/manage-rewards/create"
            component={CreateRewardPage}
            roles={['superadmin']}
          />
          <AdminRoute
            path="/landing-settings"
            component={LandingSettingsPage}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

export default AppRouter

import { hot } from 'react-hot-loader/root'
import React from 'react'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'

import Router from './Router'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/app.scss'

if (process.env.NODE_ENV !== 'production') {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const App: React.FC = () => {
  return <Router />
}

export default hot(App)

import React from 'react'
import { NavLink } from 'react-router-dom'
import Button from '../../shared/components/Button'
import Footer from '../../shared/components/Footer'
import Navbar from '../../shared/components/Navbar'

const _404 = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <div className="not_found_page">
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <h3>Page Not Found</h3>
          <NavLink to="/">
            <Button color="primary">Back To Home</Button>
          </NavLink>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default _404

import { useContext } from 'react'
import {
  useAllEPointsQuery,
  useEPointBoughtsNotificationsQuery,
  useEPointBoughtsQuery,
  useMemberSidebarNotificationQuery,
  useMembersNotificationsQuery,
  useOrdersQuery,
  useRewardsNotificationQuery,
  useWithdrawalNotificationsQuery,
} from '../../generated/graphql'
import { oc } from 'ts-optchain'
import UserContext from '../../contexts/UserContext'

type Notif =
  | 'withdrawals'
  | 'epoints'
  | 'rewards'
  | 'memberRewards'
  | 'member'
  | 'orders'
  | 'adminOrders'
  | 'claims'
  | 'adminClaims'

export const useCounterNotification = (selector: Array<Notif> = []) => {
  const { user } = useContext(UserContext)

  function isSkip(type: Notif) {
    if (!selector.length) return false

    return !selector.includes(type)
  }

  const notificationQ = useMembersNotificationsQuery({
    fetchPolicy: 'network-only',
    skip: isSkip('member'),
  })
  const memberNotification = oc(notificationQ.data).dashboardAdmin.notifMember(
    0
  )
  const pendingWithdrawals = oc(
    useWithdrawalNotificationsQuery({
      fetchPolicy: 'network-only',
      skip: isSkip('withdrawals'),
    }).data
  ).pendingWithdrawals.metadata.totalCount(0)

  const pendingEpoints = oc(
    useEPointBoughtsNotificationsQuery({
      fetchPolicy: 'network-only',
      skip: isSkip('epoints'),
    }).data
  ).pendingEpoints.metadata.totalCount(0)

  const rewardsNotif = oc(
    useRewardsNotificationQuery({
      fetchPolicy: 'network-only',
      skip: isSkip('rewards'),
    }).data
  ).adminRewards.metadata.totalCount(0)

  const memberRewards = oc(
    useMemberSidebarNotificationQuery({
      fetchPolicy: 'network-only',
      variables: { memberId: user && user.id },
    })
  ).data.rewardEarned.metadata.totalCount(0)

  const ordersNotif = oc(
    useOrdersQuery({
      fetchPolicy: 'network-only',
      skip: isSkip('orders'),
      variables: {
        filter: { product_package_eq: 0, order_status_eq: 'pending' },
        limit: 100,
      },
    }).data
  ).ePoints.metadata.totalCount(0)

  const adminOrdersNotif = oc(
    useEPointBoughtsQuery({
      fetchPolicy: 'network-only',
      skip: isSkip('adminOrders'),
      variables: {
        filter: {
          e_point_e_point_type_eq: 1,
          e_point_transfer_information_id_not_null: true,
          e_point_order_status_not_eq: 'DELIVERED',
        },
        limit: 100,
      },
    })
  ).data.ePointBoughts.metadata.totalCount(0)

  const claimsNotif = oc(
    useOrdersQuery({
      fetchPolicy: 'network-only',
      skip: isSkip('claims'),
      variables: {
        filter: { product_package_eq: 1, order_status_not_eq: 'DELIVERED' },
        limit: 100,
      },
    })
  ).data.ePoints.metadata.totalCount(0)

  const adminClaimsNotif = oc(
    useAllEPointsQuery({
      fetchPolicy: 'network-only',
      variables: {
        filter: { product_package_eq: 1, order_status_not_eq: 'DELIVERED' },
        limit: 100,
      },
      skip: isSkip('adminClaims'),
    })
  ).data.allEPoints.metadata.totalCount(0)

  const orderAndClaims = ordersNotif + claimsNotif
  const orderAndAdminClaims = adminOrdersNotif + adminClaimsNotif

  return {
    memberNotification,
    pendingWithdrawals,
    pendingEpoints,
    rewardsNotif,
    memberRewards,
    ordersNotif,
    adminOrdersNotif,
    claimsNotif,
    adminClaimsNotif,
    orderAndClaims,
    orderAndAdminClaims,
  }
}

import React from 'react'
import { UserProfileSmall } from '../../containers/application_layout/components/UserProfile'
import { LanguageSwitcher } from './LanguageSwitcher'

export const NavbarFixed = () => {
  return (
    <div className="navbar-fixed">
      <div className="d-flex justify-content-end align-items-center px-3">
        <div className="mr-3">
          <LanguageSwitcher color="primary" />
        </div>
        <div>
          <UserProfileSmall />
        </div>
      </div>
    </div>
  )
}

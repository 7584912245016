import React, { useState, Dispatch, SetStateAction } from 'react'
import Cookie from 'js-cookie'
import { useCurrentUserQuery, UserFragmentFragment } from '../generated/graphql'
import { oc } from 'ts-optchain'

type TypeUser = UserFragmentFragment | null

interface IUserContext {
  user: TypeUser
  setUser: Dispatch<SetStateAction<TypeUser>>
  loading?: boolean
  isSmall?: boolean
  isExpired?: boolean
  unPaid?: boolean
}

const initialContextValues: IUserContext = {
  user: null,
  setUser: () => {},
  isSmall: false,
  isExpired: false,
  unPaid: false,
}

const UserContext = React.createContext(initialContextValues)
UserContext.displayName = 'User'

const UserProvider: React.FC = ({ children }) => {
  const initialUser: TypeUser = localStorage.user
    ? JSON.parse(localStorage.user)
    : null

  const [user, setUser] = useState<TypeUser | null>(initialUser)
  const { data, loading } = useCurrentUserQuery({
    skip: !Cookie.get('userToken'),
    onCompleted,
  })

  const isSmall: boolean =
    oc(user)
      .package.name('')
      .toLowerCase() === SMALL

  const isExpired =
    user && user.packageExpired && Date.now() > Date.parse(user.packageExpired)
      ? true
      : false

  const unPaid: boolean =
    user && user.activePackageId && !user.packageExpired && !user.isActive
      ? true
      : false

  function onCompleted() {
    if (data && data.me) {
      localStorage.setItem('user', JSON.stringify(data.me))
      setUser(data.me)
    } else {
      Cookie.remove('userToken')
      setUser(null)
    }
  }

  return (
    <UserContext.Provider
      value={{ user, setUser, loading, isSmall, isExpired, unPaid }}
    >
      {children}
    </UserContext.Provider>
  )
}

const SMALL = 'small'

export default UserContext
export { UserProvider }
